import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';


// const API = (token) =>
//   axios.create({
//     baseURL: url,
//     headers: { Authorization: token },
//   });

const url = process.env.REACT_APP_DEPLOYMENT_URL || 'https://v2.api.rotsi.co.ke';
// console.log("Url :",url);

const PaymentsAPI = (secretKey) =>
  axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
    },
});

// SStkPush/v1

export const SstkPush = async (user, body) => {
    try {
      const { lastname, firstname, email } = user;
  
      const {amount, phone} = body;

      const isStaff = true;
    
      const { data } = await axios.post(`${url}/payments/stkPush/v1`, { lastname, firstname, email, amount, phone, isStaff});
      
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };
  
export const stkPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone} = body;
    
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/stkPush/v1`, { username, amount, phone });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const generateReceipt = async (user, receiptId) => {
    try {
      const { secretKey, username } = user;
  
      const receiptID = receiptId;
    
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/auth/receiptGeneration`, { username, receiptID });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const scanReceipt = async (receiptId) => {
    try {
      // const { secretKey, username } = user;
  
      const receiptID = receiptId;

      const { data } = await axios.post(`${url}/auth/render_scanned_receipt`, { receiptID });
      
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const accountBalance = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone} = body;
    
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/businessMpesabalance/v1`, { username, amount, phone });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const upgradeAccount = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone} = body;
    
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/goldstkupgrade/v1`, { username, amount, phone });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const rotsiaccountPayment = async (user, amount) => {
    try {
      const { secretKey, username } = user;
  
      // const {amount, phone} = body;
    
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/rotsiupgrade/v1`, { username, amount });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const dynamicQrcode = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount} = body;
    
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/dynamicQrcode/v1`, { username, amount });
      console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const staffdynamicQrcode = async (user, body) => {
    try {
      const { lastname, firstname, email } = user;
  
      const {amount} = body;

      const isStaff = true;
    
      // const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await axios.post(`${url}/payments/dynamicQrcode/v1`, { lastname, firstname, email, amount, isStaff });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;    }
  };

  export const SB2cPush = async (user, body) => {
    try {
      const { lastname, firstname, email } = user;
  
      const {amount, phone} = body;

      const isStaff = true;
    
      const { data } = await axios.post(`${url}/payments/b2c/v1`, { lastname, firstname, email, amount, phone, isStaff });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };
  
  export const b2cPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone} = body;
     
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/b2c/v1`, { username, amount, phone });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;    }
  };

  export const m2bPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone,bankcode,accountNumber} = body;
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/m2b/v1`, { username, amount, phone, accountNumber, bankcode });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const Sm2bPush = async (user, body) => {
    try {
      const { lastname, firstname, email } = user;
  
      const {amount, phone,bankcode,accountNumber} = body;
      
      const isStaff = true;
      // const PaymentsAPIInstance = PaymentsAPI(secretKey);
      
      // const { data } = await PaymentsAPIInstance.post(`${url}/payments/m2b/v1`, { username, amount, phone, accountNumber, bankcode });
      const { data } = await axios.post(`${url}/payments/m2b/v1`, { lastname, firstname, email, amount, phone ,bankcode,accountNumber, isStaff });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const m2sPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone,servicecode,accountNumber} = body;

      // console.log(body);
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/m2s/v1`, { username, amount, phone, accountNumber, servicecode });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  
  export const kraPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone,accountNumber} = body;
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const response = await PaymentsAPIInstance.post(`${url}/payments/kratax/v1`, { username, amount, phone, accountNumber });
      // console.log('Response Data:', response.data);
       
      if (response.data.error) {
        toast.error(response.data.error); // Toast the error message
        return; // Return early as there's an error
      }
      return response.data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;    }
  };

  export const bulksmsPush = async (user, messages) => {
    try {
      const { secretKey, username } = user;
    
      const payload = {
        username,
        body: {
          messages: messages.map(({ phone, message }) => ({ phone, message })),
          batchSize: messages.length,
        },
      };
  
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/sms/bulksms/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };

  export const paybillPush = async (user, body) => {
    try {
      const { secretKey, username } = user;
  
      const {amount, phone,recipientSC,accountNumber} = body;

      // console.log(body);
      // console.log(amount,phone,recipientSC,accountNumber);
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/payments/paybill/v1`, { username, amount, phone, accountNumber, recipientSC });
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      if (error.response.data.message) {
        if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          toast.info('To secure your account, We renew your credentials every hour.');
          toast.error('Kindly re-login, to resume access.');
        }else{
          toast.error(error.response.data.message) // Display error message from server
        }
      } else {
        toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
      }
      console.error(error.response.data);
      throw error.response.data;
    }
  };
  
  // export const stkCallback = async (user, RotsiCheckoutRequestID, ExternalReference ) => {
  //   try {
  //     const { secretKey, username } = user;
  
  //     console.log("RotsiCheckoutRequestId:", RotsiCheckoutRequestID);
  //     console.log("ExternalReference:", ExternalReference);
  
  //     let retries = 0;
  //     const maxRetries = 30;
  //     const retryDelay = 1000;
  //     const initialDelay = 5000;
  //     let success = false;
  
  //     // Initial delay before the first attempt
  //     await new Promise(resolve => setTimeout(resolve, initialDelay));
  
  //     while (retries < maxRetries && !success) {
  //       try {
  //         const { data } = await PaymentsAPI(secretKey).post(`${url}/payments/stkPushCallback/v1/${ExternalReference}`, { username, RotsiCheckoutRequestID });
  
  //         console.log('Callback Data:', data);
  //         success = true;
  //         return data;
  //       } catch (error) {
  //         // console.error('Error in callback:', error);
  //         // Retry after delay
  //         await new Promise(resolve => setTimeout(resolve, retryDelay));
  //         retries += 1;
  //       }
  //     }
  //     // If the maximum number of retries is reached without success, throw an error
  //     throw new Error('Max retries reached. Unable to process callback.');
  //   } catch (error) {
  //     toast.error('Failed',error);
  //     console.error('Failed', error);
  //   }
  // };
