import { useNavigate,Navigate,Redirect } from 'react-router-dom';
import { addDays, format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ChatBot from 'react-simple-chatbot';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Paper, TextField,Radio,FormControl,RadioGroup,FormControlLabel,IconButton,InputAdornment,Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Edit, SettingsBackupRestore } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import styled from 'styled-components';
import { ThreeDots,Bars } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';

// components
import Page from '../components/Page';
import DashboardCarousel from './DashboardCarousel';
import {stkPush} from '../apis/paymentapis';
import { getActiveStandingOrders } from '../apis/recurringpaymentsapis';
// import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppWidgetSummaryDeposit,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

import { useUserContext } from '../UserContext';
import Chatbot from './BusinessClients/Chatbot'; 
import Login from './Login';

import { c2btransactions, b2ctransactions, buygoodstransactions, paybilltransactions, kratransactions, m2btransactions,failedtransactions,checkbalance,mmfbalance,
  aiCreditsbalance,SMSbalance } from '../apis/gettransactions';
import { c2bMonthlytransactions,b2cMonthlytransactions,buygoodsMonthlytransactions,paybillMonthlytransactions,kraMonthlytransactions,m2bMonthlytransactions } from '../apis/getMonthlyTrans';
// ----------------------------------------------------------------------
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Define the Backdrop styled component for the popover backdrop
const Backdrop = styled(Paper)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Ensure the backdrop is above other content */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
`;
// Define the PopoverContent styled component for the popover content
const PopoverContent = styled('div')`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  padding: 24px;
  border-radius: 8px;
  z-index: 1001; /* Ensure the content is above the backdrop */
`;

const Bot = styled('div')(({ theme }) => ({
  bottom: 0,
  right: 0,
  margin: 10,
  position: 'fixed',
  width: 350,
  zIndex: 94,
}));

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

const fetchTransactions = async (user, apiFunction, setTransactions) => {
  try {
    const data = await apiFunction(user);
    setTransactions(data.numTransactions || 'Loading...');
  } catch (error) {
    console.error(`Error fetching transactions: ${error}`);
  }
};

// Function to fetch generic monthly data
const fetchDailyData = async (user, apiFunction, setDailyData) => {
  try {
    const data = await apiFunction(user);
    const transformedData = data.map(({ month, transactioncount }) => ({
      month,
      transactioncount
    }));
    setDailyData(transformedData);
  } catch (error) {
    console.error(`Error fetching monthly data: ${error}`);
  }
};

export default function DashboardApp() {
  const theme = useTheme();
  const { user, setNewBalance } = useUserContext();
  const navigate = useNavigate();
  // console.log(user); 

  const [c2bTransactions, setC2BTransactions] = useState(null);
  const [b2cTransactions, setB2CTransactions] = useState(null);
  const [paybillTransactions, setPaybillTransactions] = useState(null);
  const [buygoodsTransactions, setBuygoodsTransactions] = useState(null);
  const [kraTransactions, setKraTransactions] = useState(null);
  const [m2bTransactions, setM2bTransactions] = useState(null);
  const [failedTransactions, setFailedTransactions] = useState(null);
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [mmfbal, setMMfBalance] = useState(user ? user.mmfbalance : '');
  const [aicredits, setAICredits] = useState(user ? user.aicredits : '');
  const [smsbalance, setSMSBalance] = useState(user ? user.smsbalance : '');

  const [m2bDailyData, setM2bDailyData] = useState(null);
  // const [kraDailyData, setKraDailyData] = useState(null);
  const [b2cDailyData, setB2cDailyData] = useState(null);
  const [c2bDailyData, setC2bDailyData] = useState(null);
  const [paybillDailyData, setPaybillDailyData] = useState(null);
  const [buygoodsDailyData, setBuygoodsDailyData] = useState(null);

  const [popoverOpen, setPopoverOpen] = useState(false); // State to manage popover open/close
  const [selectedMethod, setSelectedMethod] = useState(''); 
  const [isProcessing, setIsProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(false);
  const [standingorders , setStandingOrders] = useState(null);
  const [isUseEffectLoading, setIsUseEffectLoading] = useState(true);

  const [depositMethodSelected, setDepositMethodSelected] = useState(false); 
  const [amount, setAmount] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [phone, setPhoneNumber] = useState(user ? user.phonenumber : ''); // Use user data conditionally

  useEffect(() => {
    setIsUseEffectLoading(true); // Set loading state to true initially

    const fetchData = async () => {
      try {
        fetchTransactions(user, b2ctransactions, setB2CTransactions);
        fetchTransactions(user, c2btransactions, setC2BTransactions);
        fetchTransactions(user, paybilltransactions, setPaybillTransactions);
        fetchTransactions(user, buygoodstransactions, setBuygoodsTransactions);
        fetchTransactions(user, kratransactions, setKraTransactions);
        fetchTransactions(user, m2btransactions, setM2bTransactions);
        fetchTransactions(user, failedtransactions, setFailedTransactions);
  
        // fetchBalance(user, checkbalance, setBalance);

        fetchDailyData(user, m2bMonthlytransactions, setM2bDailyData);
        fetchDailyData(user, b2cMonthlytransactions, setB2cDailyData);

        fetchDailyData(user, c2bMonthlytransactions, setC2bDailyData);
        fetchDailyData(user, paybillMonthlytransactions, setPaybillDailyData);
        fetchDailyData(user, buygoodsMonthlytransactions, setBuygoodsDailyData);
      } catch (error) {
        console.error(`Error fetching data: ${error}`);
      } finally {
        setIsUseEffectLoading(false); // Set loading state to false after fetching data
      }  
    };

    const fetchBalance = async (user, setBalance) => {
      try {
        const data = await checkbalance(user);
        const balance = data.Account_Bal;
        // console.log(balance);
        setBalance(balance);
        setNewBalance(balance);
      } catch (error) {
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        } else{
          console.error(error.message);
        }
      }
    };

    const fetchMmfBalance = async (user, setMMfBalance) => {
      try {
        const data = await mmfbalance(user);
        const mmfbal = data.Account_Bal;
        // console.log(balance);
        setMMfBalance(mmfbal);
        // setNewBalance(balance);
      } catch (error) {
        console.error(`Error fetching transactions: ${error}`);
      }
    };

    const fetchAiCreditsBalance = async (user) => {
      try {
        const data = await aiCreditsbalance(user);
        const mmfbalance = data.Account_Bal;
        // console.log(balance);
        setAICredits(mmfbalance);
        // setNewBalance(balance);
      } catch (error) {
        console.error(`Error fetching transactions: ${error}`);
      }
    };
  
    const fetchSMSBalance = async (user) => {
      try {
        const data = await SMSbalance(user);
        const smsBalance = data.Account_Bal;
        // console.log(smsBalance);
        setSMSBalance(smsBalance);
        // setNewBalance(balance);
      } catch (error) {
        console.error(`Error fetching transactions: ${error}`);
      }
    };

    const fetchStandingOrders = async (user) => {
      try {
        const responseData = await getActiveStandingOrders(user);
        const standingOrders = responseData.data;
        console.log(standingOrders);
        setStandingOrders(standingOrders);
      } catch (error) {
        console.error(`Error fetching transactions: ${error}`);
      }
    };

    if (user) {
      fetchBalance(user, setBalance);
      fetchMmfBalance(user, setMMfBalance);
      fetchAiCreditsBalance(user);
      fetchSMSBalance(user);
      fetchData(); // Fetch data only if user exists
      fetchStandingOrders(user);
    }
  }, []);  
  // }, [user]);  

  if (!user) {
    <Login />;
  }

  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }

  if(user.verificationstatus !== 'Verified'){
     return <Navigate to="/awaiting-verification" />; // Use Navigate to redirect to the login page
  }

  const handlePopoverOpen = () => {
    setPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setSelectedMethod(''); // Clear the selected deposit method
    setDepositMethodSelected(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Get the username in title case
  const usernameTitleCase = capitalizeFirstLetter(user.username);

  let paybillNumber;
  paybillNumber = user.shortcode;
  if(!paybillNumber){
    paybillNumber = 'Not-set'
  }

  const getLastSevenDays = () => {
    const today = new Date();
    const lastSevenDays = Array.from({ length: 6 }, (_, index) =>
      format(addDays(today, -index), 'MM/dd/yyyy')
    ).reverse(); // Reverse the array to have the oldest date first
    return lastSevenDays;
  };
  
  const ErrorComponent = () => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" sx={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
      <Button onClick={() => setError('')} variant="contained" style={{ marginTop: 20,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Try Again
      </Button>
    </div>
  );

  const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Processing Payment...
        </Typography>
      </div>
    </Container>
  );  

  const LoadingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <Bars visible height="80" width="80" color="#4fa94d" ariaLabel="bars-loading" />
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Retrieving Information...
        </Typography>
      </div>
    </Container>
  );  
  
  const SuccessComponent = ({ response, handleSuccessButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <Checkmark size='96px' color='green' />
      {/* <Typography variant="body1" style={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>Deposit successful!</Typography> */}
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Transaction Id: {response.RotsiAPITransactionId}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        ResponseMessage: {response.CustomerMessage}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ marginTop: 5,color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Service: {response.Service}
      </Typography>
      <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}>
        Return to Dashboard
      </Button>
    </div>
  );  

  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    setPopoverOpen(false); // Close the popover
    setSelectedMethod(''); // Clear the selected method
    navigate('/business-dashboard/app', { replace: true });
  };
  
  const handleBackButtonClick = () => {
    setSelectedMethod(''); // Clear the selected deposit method in AppWidgetSummaryDeposit
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    try {
      const formattedPhoneNumber = formatPhone(phone);
      // console.log(formattedPhoneNumber);
  
      const amountWithoutDecimals = Math.trunc(amount);
  
      const requestBody = {
        phone: formattedPhoneNumber,
        amount: amountWithoutDecimals, 
      };
      // console.log(requestBody);
      const response = await stkPush(user, requestBody);
      // const Newbalance = parseInt(balance, 10) + parseInt(amountWithoutDecimals, 10);
      // const StringBalance = Newbalance.toString();
      // setNewBalance(StringBalance);
      setSuccess(true);
      setResponse(response);
      toast.success('Rotsi Deposit successful!');
      // Handle success
    } catch (error) {
      console.error('Error initiating payment:', error);
      setError(true); // Set error state to true for rendering error view
    } finally {
      setIsProcessing(false);
    }
    setPopoverOpen(false);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    // Clear phoneError when the user starts typing again
    setPhoneError('');
  };
  // Use the function to get the last seven days' dates
  const lastSevenDays = getLastSevenDays();

  const popoverContent = (
    <PopoverContent>
      {selectedMethod ? ( 
        <>
        {isProcessing && !success && !error && (
          <ProcessingComponent/>
        )}
        {success && !isProcessing && !error && (
          // View when successful
          <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} />
        )}
        {error && !isProcessing && !success && (
          // <ErrorComponent />
          <ErrorComponent />
        )}
        {!isProcessing && !success && !error && (
          // View when form is not processing and no success/error yet
          <>
          {selectedMethod === 'paybill' && (
            <div>
              <Typography sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="h6">Deposit via Paybill</Typography>
              <Typography sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="subtitle1">Paybill: {paybillNumber}</Typography>
              <Typography sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="subtitle1">Account Number: {user.username}</Typography>
              <Button onClick={() => setSelectedMethod('')} variant="contained" style={{ borderRadius: '50%', minWidth: 0 }}>
                <ArrowBackIcon />
              </Button>
            </div>
          )}
          {selectedMethod === 'stk' && (
            <div>
              <Button onClick={() => setSelectedMethod('')} variant="contained" sx={{ marginBottom: 2 }} style={{ borderRadius: '50%', minWidth: 0 }}>
                  <ArrowBackIcon />
                </Button>
              <Typography sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="h6">Deposit via STK Push</Typography>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2} marginTop={2}>
                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <TextField
                      label="Your Phone Number"
                      variant="outlined"
                      placeholder="2547XXXXXXXX"
                      value={phone}
                      sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                      onChange={handlePhoneChange}
                      InputProps={{
                        readOnly: !isEditing,
                        endAdornment: (
                          <InputAdornment position="end">
                            {!isEditing && (
                              <IconButton onClick={handleEditClick}>
                                <Edit />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                      autoFocus
                    />
                  </FormControl>
                  {phoneError && (
                    <Typography variant="body2" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} color="error" gutterBottom>
                      {phoneError}
                    </Typography>
                  )}
                  <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    <TextField
                      label="Amount you wish to deposit"
                      variant="outlined"
                      value={amount}
                      type="number"
                      sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <LoadingButton fullWidth size="large" type="submit" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="contained" loading={isProcessing}>
                  Pay Deposit
                </LoadingButton>
              </form>
            </div>
           )}
           </>
         )}
       </>
      ) : ( // If no method is selected, show the method selection options
        <FormControl component="fieldset">
          <Typography sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} variant="h6">Deposit via Paybill or STK Push</Typography>
          <RadioGroup
            aria-label="deposit-method"
            name="deposit-method"
            value={selectedMethod}
            onChange={(e) => {
              setSelectedMethod(e.target.value);
            }}
          >
            <FormControlLabel sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} value="paybill" control={<Radio />} label="Paybill Deposit" />
            <FormControlLabel sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} value="stk" control={<Radio />} label="STK Push Deposit" />
          </RadioGroup>
        </FormControl>
      )}
    </PopoverContent>
  );  

  if (isUseEffectLoading) {
    return <LoadingComponent />;
  } 

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Hi, Welcome back {usernameTitleCase}
        </Typography>
        {popoverOpen && (
          <Backdrop onClick={handlePopoverClose}>
            <Button onClick={handleBackButtonClick}>Back</Button> 
          </Backdrop>
        )}

        <Grid container spacing={3} sx={{ marginBottom: 3, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          <Grid item xs={12}>
            <DashboardCarousel>
              <AppWidgetSummaryDeposit
                title="Utility/Merchant balance"
                total={balance}
                icon={'teenyicons:layers-intersect-solid'}
                popoverContent={popoverContent} 
                // buttonText="Deposit"
              />
              <AppWidgetSummaryDeposit
                title="Working balance"
                total={mmfbal}
                icon={'teenyicons:layers-intersect-solid'}
                popoverContent={popoverContent} 
                // buttonText="Deposit"
              />
              <AppWidgetSummaryDeposit
                title="SMS Balance"
                total={smsbalance}
                icon={'teenyicons:layers-intersect-solid'}
                popoverContent={popoverContent} 
                // buttonText="Deposit"
              />
              
              {/* <AppWidgetSummaryDeposit
                title="AI Credits"
                total={aicredits}
                icon={'teenyicons:layers-intersect-solid'}
                popoverContent={popoverContent} 
                // buttonText="Deposit"
              /> */}
            </DashboardCarousel>
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ marginBottom: 3 }}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary 
                  title="Failed Transactions" 
                  total={failedTransactions !== null ? String(failedTransactions) : 'Loading...'}
                  color="error" 
                  icon={'ant-design:bug-filled'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary
                    title="Paybill Deposits"
                    total={c2bTransactions !== null ? c2bTransactions : 'Loading...'}
                    icon={'teenyicons:layers-intersect-solid'}
                  />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                  <AppWidgetSummary
                    title="B2C Transactions"
                    total={b2cTransactions !== null ? b2cTransactions : 'Loading...'}
                    color="success"
                    icon={'teenyicons:layers-intersect-solid'}
                  />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                  title="Paybill Payments"
                  total={paybillTransactions !== null ? paybillTransactions : 'Loading...'}
                  icon={'teenyicons:layers-intersect-solid'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                  title="Buygoods Payments"
                  total={buygoodsTransactions !== null ? buygoodsTransactions : 'Loading...'}
                  icon={'teenyicons:layers-intersect-solid'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                  title="Kra Remit Payments"
                  total={kraTransactions !== null ? kraTransactions : 'Loading...'}
                  color="success"
                  icon={'teenyicons:layers-intersect-solid'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                  title="Mpesa to Bank Payments"
                  total={m2bTransactions !== null ? m2bTransactions : 'Loading...'}
                  icon={'teenyicons:layers-intersect-solid'}
                />
              </Grid>
              </Grid>
     
              <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                <Grid item xs={12} md={6} lg={6}>
                  <AppWebsiteVisits
                    title="Last 7 days Transactions"
                    subheader="Comparing with 7 days..."
                    chartLabels={lastSevenDays}
                    chartData={[
                      {
                        name: 'B2C',
                        // type: 'line',
                        // fill: 'solid',
                        type: 'column',
                        fill: 'gradient',
                        data: b2cDailyData ? b2cDailyData.map((item) => item.transactioncount) : [],
                        color: theme.palette.chart.blue[0],
                      },
                      {
                        name: 'Paybill',
                        type: 'area',
                        fill: 'gradient',
                        data: paybillDailyData ? paybillDailyData.map((item) => item.transactioncount) : [],
                      },
                      {
                        name: 'Deposits',
                        type: 'column',
                        fill: 'solid',
                        data: c2bDailyData ? c2bDailyData.map((item) => item.transactioncount) : [],
                        color: theme.palette.chart.red[0],
                      },
                      {
                        name: 'Bank',
                        type: 'line',
                        fill: 'solid',
                        // type: 'column',
                        // fill: 'gradient',
                        data: m2bDailyData ? m2bDailyData.map((item) => item.transactioncount) : [],
                        color: theme.palette.chart.green[0],
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <AppConversionRates
                    title="All Transactions Done"
                    subheader="Comparing with the last 7 days ..."
                    chartData={[
                      { label: 'B2C Transfers', value: b2cTransactions },
                      { label: 'Paybill Payments', value: paybillTransactions },
                      { label: 'Paybill Account Deposits', value: c2bTransactions },
                      { label: 'To Bank Payments', value: m2bTransactions },
                      { label: 'Tax Remittance Payments', value: kraTransactions },
                    ]}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                <Grid item xs={12} md={6} lg={4}>
                  <AppOrderTimeline
                    title="Scheduled Recurring Payments"
                    list={standingorders?.map((order, index) => ({
                      id: order.index, // Assuming 'order' has an 'id' field
                      title: `${order.standingordername} -  Next On : ${order.scheduled_time}`, // Assuming 'order' has a 'title' field
                      type: `order${index + 1}`, // You can modify the type based on your business logic
                      time: new Date(order.scheduled_time), // Assuming 'order' has a 'time' field
                    }))}
                  />
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <AppNewsUpdate
                  title="New Businesses Corner"
                  subheader="Supporting other businesses in the Rotsi Eco-System"
                  list={[...Array(1)].map((_, index) => ({
                    id: faker.datatype.uuid(),
                    title: "ROTSI API SOLUTIONS",
                    description: "Payments and SMS solutions",
                    image: `/static/mock-images/covers/cover_${index + 1}.jpg`,
                    postedAt: faker.date.recent(),
                  }))}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Bot>
                  <Chatbot/>
                </Bot>
              </Grid>
            </Grid>
      </Container>
    </Page>
  );
}

