import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Typography, TextField, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton'; 
import Page from '../components/Page';
import { getinvitedetails } from '../apis/generatelink';

const formatPhone = (phone) => {
    phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
        formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
        return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
        formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
        formattedPhone = `254${phone}`;
    } else if (phone.startsWith('11') && phone.length === 9) {
        formattedPhone = `254${phone}`;
    } else {
        formattedPhone = null;
    }
    return formattedPhone;
};

export default function PaymentInviteLink() {
    const location = useLocation();
    const [inviteLink, setInviteLink] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phone, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [taskDetails, setTaskDetails] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const inviteLinkParam = searchParams.get('paymentlink');
        if (!inviteLinkParam) {
            navigate('/login');
        }
        setInviteLink(inviteLinkParam);

        const fetchTaskDetails = async () => {
            try {
                const taskDetails = await getinvitedetails({ invitelink: inviteLinkParam });
                setTaskDetails(taskDetails);
            } catch (error) {
                console.error('Error fetching task details:', error);
            }
        };

        fetchTaskDetails();
    }, [location.search, navigate]);

    const handlePhoneChange = (e) => {
        const { value } = e.target;
        setPhoneNumber(value);
        setPhoneError('');
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const formattedPhoneNumber = formatPhone(phone);
        if (formattedPhoneNumber === null) {
            setPhoneError('Phone number must start with 254 and have a total of 12 digits.');
            setIsSubmitting(false);
            return;
        }
        try {
            toast.success('Payment Confirmed!');
        } catch (error) {
            toast.error('Failed to confirm Payment. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Page title="Rotsi Generated Payment Link Invite">
            <Container
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    padding: '20px',
                }}
            >
                <form onSubmit={handleFormSubmit} style={{ width: '100%', maxWidth: '600px' }}>
                    <InputLabel 
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                            Payment Initiated By
                    </InputLabel>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                            variant="outlined"
                            value={taskDetails?.initiatorname || ''}
                            InputProps={{ readOnly: true }}
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                            fullWidth
                        />
                    </FormControl>

                    <InputLabel 
                        sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                        Amount Expected to Pay
                    </InputLabel>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                            variant="outlined"
                            value={taskDetails?.amount || ''}
                            InputProps={{ readOnly: true }}
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                            fullWidth
                        />
                    </FormControl>

                    <InputLabel 
                        sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                        Reason for Payment
                    </InputLabel>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                            variant="outlined"
                            value={taskDetails?.paymentreason || ''}
                            InputProps={{ readOnly: true }}
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                            fullWidth
                        />
                    </FormControl>

                    <InputLabel 
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                            Your Phone Number
                    </InputLabel>
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <TextField
                            label="Your Phone Number"
                            variant="outlined"
                            placeholder="2547XXXXXXXX"
                            value={phone}
                            onChange={handlePhoneChange}
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                            fullWidth
                        />
                        {phoneError && (
                            <FormHelperText error sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                                {phoneError}
                            </FormHelperText>
                        )}
                    </FormControl>
                    
                    <FormControl fullWidth sx={{ marginTop: 2 }}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                        >
                            Make Payment
                        </LoadingButton>
                    </FormControl>
                </form>
            </Container>
        </Page>
    );
}