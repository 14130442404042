// import { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = process.env.REACT_APP_DEPLOYMENT_URL || 'https://v2.api.rotsi.co.ke';
// console.log("Url :",url);

export const loginUser = async (body) => {
  try {
    const response = await axios.post(`${url}/auth/login`, body);
    toast.success('Login successful');
    return response;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const enableSMS = async (user,body) => {
  try {
    const { secretKey, username } = user;
    
    const { newEnableSMS } = body;

    const emailMeApiInstance = EmailMeAPI(secretKey);

    const { data } = await emailMeApiInstance.post(`${url}/auth/enablesms`, { username, activestatus:newEnableSMS });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
}

export const resetPassword = async (body) => {
  try {
    const { token,newPassword } = body 

    const response = await axios.post(`${url}/auth/resetpassword`, body);
    // Assuming the API returns an error message in the response if there's an error
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    toast.success('Password reset successful. Please log in with your new password.');
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};
export const forgotPassword = async (body) => {
  try {
    const response = await axios.post(`${url}/auth/forgotpassword`, body);
    // Assuming the API returns an error message in the response if there's an error
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    toast.success('Email sent successfully');
    return response.data; // Return response data if needed
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

const EmailMeAPI = (secretKey) =>
  axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: secretKey,
    }
});

export const contactemail = async (user, body) => {
  try {
    const { secretKey, username } = user;

    const emailMeApiInstance = EmailMeAPI(secretKey);
    // console.log('Body:', body);
    const { data } = await emailMeApiInstance.post(`${url}/auth/emailme`, { username, body });
    // const { data } = await EmailMeAPI(secretKey).post(`${url}/auth/emailme`, { username, body });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const registerUser = async (body) => {
  try {
    const response = await axios.post(`${url}/auth/register`, body);
    toast.success('Registration success. Welcome to Rotsi');
    return response;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const referralProgram = async (body) => {
  try {
    const { phone, email, name } = body;
    console.log(phone, email, name);
    
    const { data } = await axios.post(`${url}/auth/referralprogram`, body );
    console.log(data);  
    // toast.success('Referral code applied successfully');
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const insertwithdrawalDetails = async (user,body) => {
  try {
    const { secretKey, username } = user;

    const { phonenumber,bank,bankcode,AccountNumber } = body;

    // console.log(body);

    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    // console.log('Body:', body);
    const data  = await withdrawalDetailsApiInstance.post(`${url}/auth/withdrawaldetails`, { username, body });
    toast.success('Withdrawal details saved successfully');
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const profileupdate = async (user,body) => {
  try {
    const { secretKey, username } = user;

    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    // console.log('Body:', body);
    const data  = await withdrawalDetailsApiInstance.post(`${url}/auth/profileupdate`, { username, body });
    toast.success('Profile update successful');
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const extraPersonnel = async (user,body) => {
  try {
    const { secretKey, username } = user;

    // console.log(user);

    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    // console.log('Body:', body);
    const data  = await withdrawalDetailsApiInstance.post(`${url}/auth/adduser`, { username, body });
    toast.success('Profile update successful');
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const businessdocs = async (body) => {
  try {
    const response = await axios.post(`${url}/auth/businessdocs`, body);
    // toast.success('Registration success. Welcome to Rotsi');
    return response;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const getBusinessOrders = async (user) => {
  try {
    const { secretKey, username } = user;
  
    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    const { data }  = await withdrawalDetailsApiInstance.post(`${url}/auth/getusers`, { username });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const getOthersBusiness = async (user) => {
  try {
    const { secretKey, username } = user;
  
    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    const { data }  = await withdrawalDetailsApiInstance.post(`${url}/auth/getotherbusinesses`, { username });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const updateUserPermission = async (user,selectedUser,permissions) => {
  try {
    const { secretKey, username } = user;  
    const { email} = selectedUser;
    const payload = {
      username, 
      permissions
    };
    // console.log(payload);

    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    const { data }  = await withdrawalDetailsApiInstance.post(`${url}/auth/updateuserpermissions`, { username,permissions,email });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const updateUserStatus = async (user,selectedUser,newStatus) => {
  try {
    const { secretKey, username } = user;  
    const { email} = selectedUser;
    const payload = {
      username, 
      newStatus,
      email
    };
    // console.log(payload);
    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    const { data }  = await withdrawalDetailsApiInstance.post(`${url}/auth/updateuserstatus`, { username,newStatus,email });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const updateUser = async (user,selectedUser,newStatus) => {
  try {
    const { secretKey, username } = user;  
    const { email} = selectedUser;
    const payload = {
      username, 
      newStatus,
      email
    };
    // console.log(payload);
    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    const { data }  = await withdrawalDetailsApiInstance.post(`${url}/auth/updateuserstatus`, { username,newStatus,email });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const loginAsKing = async (user,selectedUser) => {
  try {
    const { secretKey, username } = user;  
    const { id} = selectedUser;

    console.log(selectedUser);
    console.log(user);

    const withdrawalDetailsApiInstance = EmailMeAPI(secretKey);
    const { data }  = await withdrawalDetailsApiInstance.post(`${url}/auth/loginasking`, { username, id });
    return data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};