import axios from 'axios';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';

const url = process.env.REACT_APP_DEPLOYMENT_URL || 'https://v2.api.rotsi.co.ke';

// eslint-disable-next-line camelcase
const PaymentsAPI = (secretKey) =>
  axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
    },
});

export const escrowcreation = async (user, body) => {
  try {
    const { secretKey, username } = user;

    const { role,amount, item, itemDescription, releaseCondition } = body;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/escrow/walletcreation/v1`,
     { username, amount, item, itemDescription, releaseCondition,role });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};


export const getinvitedetails = async (user, body) => {
  try {
    const { secretKey, username } = user;
    // const secretKey = "";
    // const username = "bluezee";

    const { invitelink } = body;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/escrow/getinvitedetails/v1`,
     { username, invitelink });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const acceptinvite = async (user, inviteLink) => {
  try {
    const { secretKey, username } = user;

    // const { inviteLink } = body;
    // console.log(inviteLink);

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/escrow/invitejoin/v1`,
     { username, inviteLink });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const alertsender = async (user, inviteLink) => {
  try {
    const { secretKey, username } = user;

    // const { inviteLink } = body;
    // console.log(inviteLink);

    const uniquecode = inviteLink;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/escrow/alertsender/v1`,
     { username, uniquecode });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const getwalletdetails = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/escrow/getwalletdetails/v1`,
     { username });
    
    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    // console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const initiatePayment = async (body,user) => {
  try {
    const { secretKey, username,Email } = user;
    const { amount, phone,uniquecode,companyname } = body;

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const response  = await PaymentsAPIInstance.post(`${url}/escrow/initiateescrow/v1`,
     { username,amount,phone,uniquecode,Email,companyname });

    if (response.data.error) {
      toast.error(response.data.error); // Toast the error message
      return; // Return early as there's an error
    }
    return response.data;
  } catch (error) {
    if (error.response.data.message) {
      if(error.response.data.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        toast.info('To secure your account, We renew your credentials every hour.');
        toast.error('Kindly re-login, to resume access.');
      }else{
        toast.error(error.response.data.message) // Display error message from server
      }
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    console.error(error.response.data);
    throw error.response.data;
  }
};