import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate,Link as RouterLink } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton, FormControl, InputAdornment,Card,CardContent,Link } from '@mui/material';
import { Edit, RestaurantRounded } from '@mui/icons-material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ShareIcon from '@mui/icons-material/Share';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'; // Import toast library
import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import Page from '../../components/Page';
import { useUserContext } from '../../UserContext';
// import { checkbalance } from '../../apis/gettransactions';
import { generateLink } from '../../apis/generatelink'; // Import generateLink function
import Login from '../Login';

const formatPhone = (phone) => {
  phone = String(phone);
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('011') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('0') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    }else if (phone.startsWith('11') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
};

export default function B2CForm() {
  const { user, setNewBalance } = useUserContext();
  const navigate = useNavigate();
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [amount, setAmount] = useState('');
  const [phone, setPhoneNumber] = useState(user ? user.phonenumber : '');
  const fullname = `${user.firstname} ${user.lastname}`;
  const [initiatorName, setInitiatorName] = useState(fullname);
  const [paymentReason, setpaymentReason] = useState('');
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [paymentError, setPaymentError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [amountError, setAmountError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [response, setResponse] = useState(null); // State to hold response data
  const [transactionCost, setTransactionCost] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  // let totalTransactionCost = 0;
  // console.log(user.firstname);
  // console.log(user.lastname);


  // useEffect(() => {
  //   if (user) {
  //     fetchBalance(user, setBalance);
  //   }
  // // }, [user]);  
  // }, []);  

  useEffect(() => {
    // Check if the user is on a mobile device
    const checkMobileDevice = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkMobileDevice();
    // Optionally add a resize event listener if you want to update this on window resize
  }, []);

  if (!user) {
    <Login />;
  }
  if (!user) {
    return <Navigate to="/login" />; // Use Navigate to redirect to the login page
  }
  

//   if (user.role !== 'individualclients') {
//     return <Navigate to="/dashboard" />; // Use Navigate to redirect to the login page
//   }

  // const fetchBalance = async (user, setBalance) => {
  //   try {
  //     const data = await checkbalance(user);
  //     const bal = data.Account_Bal;
  //     setBalance(bal); // Set balance state
  //   } catch (error) {
  //     console.error(`Error fetching transactions: ${error}`);
  //   }
  // };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    // Clear phoneError when the user starts typing again
    setPhoneError('');
  };

  const handleSuccessButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/dashboard/app', { replace: true });
  };

  const ErrorComponent = ({ handlePaymentErrorButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" sx={{ marginTop: 10,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
      <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 }}>
        Try Again
      </Button>
    </div>
  );

  const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Generating Payment Link ...
        </Typography>
      </div>
    </Container>
  );  

  const SuccessComponent = ({ response, handleSuccessButtonClick, handleCopyLink, isMobile }) => (
    <div style={{ textAlign: 'center' }}>
      <Checkmark size='96px' color='green' />
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Payment Link Generated successful!
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Payment Link: {response.url}
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Service: {response.Service}
      </Typography>
      <IconButton onClick={handleCopyLink} aria-label={isMobile ? 'Share link' : 'Copy link'}>
        {isMobile ? <ShareIcon /> : <FileCopyIcon />}
      </IconButton>
      <Container>
        <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Return to Dashboard
        </Button>
      </Container>
    </div>
  );

  const handleAmountChange = (e) => {
    // setTransactionCost(false); 
    const { value } = e.target;
    setAmount(value);
    // Clear phoneError when the user starts typing again
    setError('');
  };

  const handleCopyLink = () => {
    const inviteLink = `${response.url}`;

    if (isMobile) {
      if (navigator.share) {
        navigator.share({
          title: 'Share out this payment to receive payment!',
          url: inviteLink,
        })
        .then(() => toast.success('Link shared successfully'));
      } else {
        navigator.clipboard.writeText(inviteLink);
        toast.success('Link copied to clipboard');
      }
    } else {
      navigator.clipboard.writeText(inviteLink)
        .then(() => toast.success('Link copied to clipboard'));
    }
  };


  const handleinitiatorNameChange = (e) => {
    // setTransactionCost(false); 
    const { value } = e.target;
    setInitiatorName(value);
    // Clear phoneError when the user starts typing again
    setError('');
  };

  const handlePaymentReasonChange = (e) => {
    // setTransactionCost(false); 
    const { value } = e.target;
    setpaymentReason(value);
    // Clear phoneError when the user starts typing again
    setError('');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      // Prepare the request body
      if(!amount) {
        setError('Please enter the amount to be paid'); 
        setIsSubmitting(false);
        return;
      }
      if(!paymentReason) {
        setError('Please enter the reason for payment'); 
        setIsSubmitting(false);
        return;
      } 
      if(!initiatorName) {
        setError('Please enter the name of the initiator'); 
        setIsSubmitting(false);
        return;
      }                     
      const requestBody = {
        initiatorName,
        paymentReason,
        amount
      };
      // console.log('Request Body:', requestBody);

      // Perform the withdrawal operation
      const responseData = await generateLink(user, requestBody);
      setSuccess(true);
      setResponse(responseData);
      // Display success message and navigate to login page
    //   toast.success('Rotsi to Mpesa Withdrawal successful!');
      // navigate('/login');
    } catch (error) {
      if(error.message){
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        }else{
          setError(true);
          setPaymentError(error.message);
        }
      }else{
        setError(true);
        setPaymentError('Withdrawal failed. Please try again later.');
      }
    } finally {
      setIsSubmitting(false);
      setIsEditing(false);
    }
  };

  return (
    <Page title="Rotsi Generative Link">
      <Container>
      {isSubmitting && <ProcessingComponent />}
      {paymentError && <ErrorComponent handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick} handleCopyLink={handleCopyLink} isMobile={isMobile} />}

      {!isSubmitting && !success && !paymentError && (
        <div>
        <Typography variant="h4" gutterBottom sx={{ marginBottom: 8, color: 'black',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Generate an Easy to share Payment Link. Currently supporting Mpesa Only. Visa and Airtel Money in Development.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Withdraw from Account
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Payment Reason"
              variant="outlined"
              value={paymentReason}
              placeholder="Eg. Completion of Online Task,etc."  
              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              onChange={handlePaymentReasonChange}
            />
          </FormControl>
          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Payment initiated by"
              variant="outlined"
              value={initiatorName}
              placeholder="Eg. John Doe etc. A name the payer recognises you by ..."  
              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              onChange={handleinitiatorNameChange}
            />
          </FormControl>
          {phoneError && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {phoneError}
            </Typography>
          )}

          <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
            <TextField
              label="Amount to be paid"
              variant="outlined"
              value={amount}
              type="number"
              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              onChange={handleAmountChange}
            />
          </FormControl>
          
          {error && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {error}
            </Typography>
          )}
          
          <LoadingButton type="submit" variant="contained" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} disabled={isSubmitting}>
            {isSubmitting ? `Generating Payment Link`: 'Generate Payment Link'}
          </LoadingButton>
        </form>
      </div>
      )}
      </Container>
    </Page>
  );
}