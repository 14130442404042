// src/ReceiptPage.js
import React from 'react';
import { Container, Typography } from '@mui/material';
import QRCode from 'qrcode.react';

const ReceiptPage = React.forwardRef(({ receiptData }, ref) => {
  const url = 'https://dashboard.rotsi.co.ke' || process.env.REACT_APP_FRONT_URL;  
  const transactionId = receiptData[0].transactionId || receiptData[0].transactionid; 
  const qrcodelink = `${url}/scanned-receipt/${transactionId}`;
  console.log(qrcodelink);
  const timestamp = receiptData[0].timestamp;
  const formattedTimestamp = timestamp.replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3T$4:$5:$6Z');
  const date = new Date(formattedTimestamp);

  return (
    <Container ref={ref} style={styles.container}>
      <div style={styles.header}>
        <img src="https://dashboard.rotsi.co.ke/static/rotsi.png" alt="Rotsi Logo" style={styles.logo} />
        <Typography sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem', color: '#4287f5' }}>Receipt for {receiptData[0].company_name}.</Typography>
        <Typography sx={{ marginTop: 2, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem', color: '#4490a8' }}>
          Thank you for elevating your Business Experience with ROTSI.
        </Typography>
      </div>
      <div style={styles.content}>
        <div style={styles.row}>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >Total Amount Paid Out:</Typography>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >KES {receiptData[0].amount}</Typography>
        </div>
        <div style={styles.row}>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >Recipient Name:</Typography>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >{receiptData[0].recipientname}</Typography>
        </div>
        <div style={styles.row}>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >Phone Number:</Typography>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >{receiptData[0].phone}</Typography>
        </div>
        <div style={styles.row}>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >Date:</Typography>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >{date.toLocaleString()}</Typography>
        </div>
        <div style={styles.row}>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >Mpesa Receipt ID:</Typography>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >{receiptData[0].mpesareceipt}</Typography>
        </div>
        <div style={styles.row}>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >Rotsi ID:</Typography>
          <Typography sx ={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem',marginLeft: 'auto'}} >{receiptData[0].transactionid}</Typography>
        </div>
        <div style={styles.qrCode}>
          <QRCode value={qrcodelink} />
        </div>
      </div>
      <div style={styles.footer}>
        <div style={styles.footerRow}>
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '0.9rem', color: '#4c4c54', textAlign: 'center', marginBottom: '10px' }}>
            Contact us at support@rotsi.co.ke in case of a question.
          </Typography>
        </div>
        <div style={styles.footerRow}>
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem', color: '#4490a8', textAlign: 'center', }}>
            ROTSI
          </Typography>  
        </div>
        <div style={styles.footerRow}>
          <Typography sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem', color: '#4490a8', textAlign: 'center' }}>
            Elevate your Business Experience
          </Typography>
        </div>
        <img src="https://dashboard.rotsi.co.ke/static/rotsi.png" alt="Rotsi Logo" style={styles.footerlogo} />
      </div>  
    </Container>
  );
});

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    width: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
    display: 'flex', // Added display flex
    flexDirection: 'column', // Added column direction to stack elements vertically
    alignItems: 'center', // Center items horizontally
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  logo: {
    width: '50px',
    height: 'auto',
    marginBottom: '10px',
  },
  content: {
    marginBottom: '10px',
    justifyContent: 'flex-start',
  },
  footer: {
    marginTop: '10px',
    textAlign: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '10px',
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  },
  label: {
    fontWeight: 'bold',
  },
  value: {
    // marginLeft: '10px',
    marginLeft: 'auto', 
  },
  qrCode: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  footerlogo: {
    width: '100px',
    height: 'auto',
    margin: '0 auto', // Center the logo horizontally
  },
};

export default ReceiptPage;
