import React, { useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../UserContext';


// const API = (token) =>
//   axios.create({
//     baseURL: url,
//     headers: { Authorization: token },
//   });

const url = process.env.REACT_APP_DEPLOYMENT_URL || 'https://v2.api.rotsi.co.ke';
// console.log("Url :",url);

const PaymentsAPI = (secretKey) =>
  axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': secretKey,
    },
});

export const getStandingOrders = async (user) => {
  try {
    const { secretKey, username } = user;
  
    const payload = {
      username,
    };

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const { data } = await PaymentsAPIInstance.post(`${url}/recurring/getAllStandingOrders`, payload);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const getActiveStandingOrders = async (user) => {
  try {
    const { secretKey, username } = user;
  
    const payload = {
      username,
    };

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const { data } = await PaymentsAPIInstance.post(`${url}/recurring/getActiveStandingOrders`, payload);
    console.log('Response Data:', data);
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};
// eslint-disable-next-line camelcase
export const updateStandingOrders = async (user,task_id, newPayments) => {
  try {
    const { secretKey, username } = user;
  
    const payload = {
      username,
      // eslint-disable-next-line camelcase
      task_id, 
      newPayments,
      // transactioncost
    };
    
    // console.log(payload);

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const { data } = await PaymentsAPIInstance.post(`${url}/recurring/updatepayments`, payload);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    // console.error('Error in Email:', error);
    // console.error(error.response.data.Message);
    // toast.error(error.response.data.Message);
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

// task_id, newStatus, user
// eslint-disable-next-line camelcase
export const updateTaskStatus = async (task_id, newStatus, user) => {
  try {
    const { secretKey, username } = user;
  
    const payload = {
      username,
      // eslint-disable-next-line camelcase
      task_id, 
      newStatus
    };
    
    // console.log(payload);

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const { data } = await PaymentsAPIInstance.post(`${url}/recurring/updatetaskstatus`, payload);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    // console.error('Error in Email:', error);
    // console.error(error.response.data.Message);
    // toast.error(error.response.data.Message);
    if (error.response.data.message) {
      toast.error(error.response.data.message) // Display error message from server
    } else {
      toast.error('We are currently experiencing some problems. Please try again later.'); // Generic error message
    }
    throw error.response.data;
  }
};

export const bulkb2cpayments = async (user,requestBody) => {
  try {
    const { secretKey, username } = user;
    const { payments,taskname,period,time} = requestBody;
    // console.log(requestBody);
    // console.log(requestBody)
  
    const payload = {
      username,
      payments,
      taskname, period, time
    };
    
    // console.log(payload);

    const PaymentsAPIInstance = PaymentsAPI(secretKey);
    const { data } = await PaymentsAPIInstance.post(`${url}/recurring/b2c/v1`, payload);
    // console.log('Response Data:', data);
    return data;

  } catch (error) {
    // console.error('Error in Email:', error);
    // console.error(error.response.data.Message);
    toast.error(error.response.data.Message);
    throw error.response.data;
  }
};


  export const bulkpaybillpayments = async (user,requestBody) => {
    try {
      const { secretKey, username } = user;

      const { payments,taskname,period,time} = requestBody;
      // console.log(requestBody);
      // console.log(requestBody)
    
      const payload = {
        username,
        payments,
        taskname, period, time
      };
      
      // console.log(payload);

      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/recurring/paybill/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      // console.error('Error in Email:', error);
      // console.error(error.response.data.Message);
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };

  export const bulkm2bpayments = async (user,requestBody) => {
    try {
      const { secretKey, username } = user;
      const { payments,taskname,period,time} = requestBody;
      // console.log(requestBody);
      // console.log(requestBody)
    
      const payload = {
        username,
        payments,
        taskname, period, time
      };
      
      // console.log(payload);

      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/recurring/m2b/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      // console.error('Error in Email:', error);
      // console.error(error.response.data.Message);
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };

  export const bulkbuygoodspayments = async (user,requestBody) => {
    try {
      const { secretKey, username } = user;
      const { payments,taskname,period,time} = requestBody;
      // console.log(requestBody);
      // console.log(requestBody)
    
      const payload = {
        username,
        payments,
        taskname, period, time
      };
      
      // console.log(payload);

      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/recurring/buygoods/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      // console.error('Error in Email:', error);
      // console.error(error.response.data.Message);
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };


  export const bulkpaybillm2bpayments = async (user,requestBody) => {
    try {
      const { secretKey, username } = user;
      const { payments,taskname,period,time} = requestBody;
      // console.log(requestBody);
      // console.log(requestBody)
    
      const payload = {
        username,
        payments,
        taskname, period, time
      };
      
      // console.log(payload);
      
      const PaymentsAPIInstance = PaymentsAPI(secretKey);
      const { data } = await PaymentsAPIInstance.post(`${url}/recurring/paybillm2b/v1`, payload);
      // console.log('Response Data:', data);
      return data;
    } catch (error) {
      // console.error('Error in Email:', error);
      // console.error(error.response.data.Message);
      toast.error(error.response.data.Message);
      throw error.response.data;
    }
  };