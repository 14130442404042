// import React, { useEffect } from 'react';
import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import { useUserContext } from '../UserContext';

const url = process.env.REACT_APP_DEPLOYMENT_URL || 'https://v2.api.rotsi.co.ke';

const GetTransactionsAPI = (secretKey, username) => {
    return axios.create({
      baseURL: url,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': secretKey,
        'Username': username, // Add the 'Username' header
      },
    });
  };
export const b2cMonthlytransactions = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    // console.log('PaymentsAPIInstance : ', PaymentsAPIInstance);

    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getMonthlyTransactionsB2C`);
  
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const c2bMonthlytransactions = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    // console.log('PaymentsAPIInstance : ', PaymentsAPIInstance);

    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getMonthlyTransactionsC2B`);
  
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const paybillMonthlytransactions = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getMonthlyTransactionsPaybill`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const buygoodsMonthlytransactions = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getMonthlyTransactionsBuygoods`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const kraMonthlytransactions = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getMonthlyTransactionsKra`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};

export const m2bMonthlytransactions = async (user) => {
  try {
    const { secretKey, username } = user;

    const PaymentsAPIInstance = GetTransactionsAPI(secretKey, username);
    const { data } = await PaymentsAPIInstance.get(`${url}/auth/getMonthlyTransactionsM2b`);
    // console.log('Response Data:', data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    throw error.response.data;
  }
};
