import React, { useEffect, useState } from 'react';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
import { Container, Table, TableBody, TableCell, TableContainer,TextField,IconButton, TableHead, TableRow, Paper, Card, TablePagination, Button, Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ColorRing } from 'react-loader-spinner';
import { getOthersBusiness,updateUserPermission,loginAsKing,extraPersonnel,updateUserStatus, } from '../../apis/auth';
import { useUserContext } from '../../UserContext';

const ProcessingComponent = ({message}) => (
  // View when processing
  <Container style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh', // Ensures the container takes the full height of the viewport
  }}>
    <div style={{ textAlign: 'center' }}>
      <ColorRing visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="color-ring-loading" wrapperStyle={{}} wrapperClass="color-ring-wrapper" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/>
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          {message}
      </Typography>
    </div>
  </Container>
); 

const BusinessUsers = () => {
  const { user, setUserDetails } = useUserContext();
  console.log(user.subscriptionend);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedPermission, setSelectedPermission] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [response, setResponse] = useState(null); // State to hold response data
  const [success, setSuccess] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false);
  const [employeeFields, setEmployeeFields] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmpassword: '',
    permissions: '',
  });

  const [isUploading, setIsUploading] = useState(false);
  // const [fetching, setFetching ] = useState(false);

  useEffect(() => {
      if (!user) {
      navigate('/login'); // Navigate to the login page if user is not available
      }
  }, [user, navigate]);

  useEffect(() => {
      if (user) {
          fetchDetails();   
      }
  }, [user]);

  const fetchDetails = async () => {
      try {
        setLoading(true);
          const responseData = await getOthersBusiness(user);
          setUsers(responseData.data); // Adjusted to match the expected data structure
      } catch (error) {
          if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
              navigate('/login');
          } else{
              setError(error.message);
          }
      } finally {
          setLoading(false);
      }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleLoginAsKing = async (selectedUser) => {
    try {
      setLoading(true);
      const response = await loginAsKing(user,selectedUser);
      setUserDetails(response.user);
      toast.success(`Logged in to ${selectedUser.company_name} successfully`)
      navigate('/business-dashboard/app', { replace: true });
    } catch (error) {
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        }else{
          toast.error('Failed to update permission');
          setError(error.message);
          setLoading(true);
        }
    } finally {
        setLoading(false);
        fetchDetails();
    };
  };

  return (
    <Container style={{ marginBottom: '30px' }}>
        {loading && <ProcessingComponent message={"Fetching your Other Businesses..."} />}
        {error && <Typography color="error">{error}</Typography>}
        {!loading && !error && (
          <>
            <Container>
            <Typography
                  variant="body2"
                  align="left"
                  display="flex"
                  alignItems="left"
                  justifyContent="left"
                  sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                  marginTop="10px"
              >
                  Below is a list of your other businesses. Click login.
              </Typography>
              <Card>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Short Code</TableCell>
                        <TableCell>Balance</TableCell>
                        <TableCell>MMF Balance</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>SMS Balance</TableCell>
                        <TableCell>AI Credits</TableCell>
                        <TableCell>Date Joined</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>{user.company_name}</TableCell>
                          <TableCell>{user.shortcode}</TableCell>
                          <TableCell>{user.balance}</TableCell>
                          <TableCell>{user.mmfbalance}</TableCell>
                          <TableCell>{user.status}</TableCell>
                          <TableCell>{user.smsbalance}</TableCell>
                          <TableCell>{user.aicredits}</TableCell>
                          <TableCell>{user.date_joined}</TableCell>
                          <TableCell>
                            <LoadingButton onClick={() => handleLoginAsKing(user)} variant="contained" color='primary'>
                              Login
                            </LoadingButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </Card>
            </Container>
          </>
        )}
    </Container>
  );
};

export default BusinessUsers;