import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import {
  Container,
  Typography,
  TextField,
  Button,
  IconButton,
  MenuItem,
  Select,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
} from '@mui/material';
import { Edit, Delete, ArrowBack, ArrowForward } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark';
import { IoMdAlert } from 'react-icons/io';
import { useUserContext } from '../../../../UserContext';
import { checkbalance } from '../../../../apis/gettransactions';
import { bulkb2cpayments } from '../../../../apis/recurringpaymentsapis';

const ProcessingComponent = () => (
  <Container
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
    }}
  >
    <div style={{ textAlign: 'center' }}>
      <ThreeDots visible height="80" width="80" color="#4fa94d" radius="9" ariaLabel="three-dots-loading" />
      <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Processing Payment...
      </Typography>
    </div>
  </Container>
);

const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
  <div style={{ textAlign: 'center' }}>
    <IoMdAlert size={24} color="red" />
    <Typography variant="body1" color="error" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      An error occurred!
    </Typography>
    <Typography 
      variant="body1" color="error" 
      style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      {paymentError.Service}
    </Typography>
    <Typography 
      variant="body1" color="error" 
      style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      {paymentError.Message}
    </Typography>
    {paymentError.TotalAmount &&
      <Typography 
        variant="body1" color="error" 
        style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        {paymentError.TotalAmount}
      </Typography>
    }
    {paymentError.AvailableBalance &&
      <Typography 
        variant="body1" color="error" 
        style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        {paymentError.AvailableBalance}
      </Typography>
    }
    {paymentError.Error &&
      <Typography 
        variant="body1" color="error" 
        style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        {paymentError.Error}
      </Typography>
    }
    {paymentError.BulkRequests.unsuccessful.map((request, index) => (
      <div key={index}>
        <Typography variant="body1" gutterBottom sx={{ color: 'red', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Unsuccessful Disbursement: Phone - {request.phone}, Status - {request.status}, Error - {request.error || request.message}
        </Typography>
      </div>
    ))}
    <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Try Again
    </Button>
  </div>
);

const SuccessComponent = ({ response, handleSuccessButtonClick}) => (
  <div style={{ textAlign: 'center' }}>
    <Checkmark size="96px" color="green" />
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Service: {response.Service}
    </Typography>
    <Typography variant="body1" gutterBottom sx={{ color: 'green', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Message: {response.Message}
    </Typography>
    <Button onClick={handleSuccessButtonClick} variant="contained" style={{ marginTop: 20, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
      Return to Dashboard
    </Button>
  </div>
);

export default function ManualEntryForm({ payments, setPayments, handleDelete, handleBack }) {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [paymentAmount, setPaymentAmount] = useState('');
  const [phonenumber, setPhoneNumber] = useState('');
  const [phoneFormatError, setPhoneFormatError] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);
  const [balance, setBalance] = useState(user ? user.balance : '');
  const [transactionCost, setTransactionCost] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(false);
  let totalTransactionAmount = 0;
  let totalTransactionCost = 0;

  const [loading, setLoading] = useState(false);

  const [emptyfields, setEmptyfields] = useState(false);
  const [step, setStep] = useState(1);
  const [taskname, setTaskName] = useState('');
  const [periodValue, setPeriodValue] = useState('');
  const [periodUnit, setPeriodUnit] = useState('months');
  // const [time, setTime] = useState('');

  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [initialTime, setInitialTime] = useState('');

  useEffect(() => {
    if (user) {
      fetchBalance(user, setBalance);
    }
  }, [user]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleNextStep = () => {
    setEmptyfields(false);
    if (taskname === '') {
      setEmptyfields('Task Name field is missing');
      return;
    }
    if (periodValue === '') {
      setEmptyfields('Period field is missing');
      return;
    }
    if (date === '') {
      setEmptyfields('Time fields is missing');
      return;
    }
    if (time === '') {
      setEmptyfields('Time fields is missing');
      return;
    }
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const fetchBalance = async (user, setBalance) => {
    try {
      const data = await checkbalance(user);
      const bal = data.Account_Bal;
      setBalance(bal);
    } catch (error) {
      console.error(`Error fetching transactions: ${error}`);
    }
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setPaymentAmount(value);
    setError('');
  };

  const handleSuccessButtonClick = () => {
    setSuccess(false);
    navigate('/business-dashboard/app', { replace: true });
  };

  const clearPhoneErrors = () => {
    setPhoneFormatError('');
  };

  const showToast = (message) => {
    toast.success(message, {
      position: 'bottom-right',
      autoClose: 3000,
      hideProgressBar: true,
    });
  };

  const formatPhoneNumber = (phone) => {
    let formattedPhone = phone;
    if (phone.startsWith('+254') && phone.length === 13) {
      formattedPhone = `254${phone.substr(4)}`;
    } else if (phone.length === 12 && phone.startsWith('254')) {
      return formattedPhone;
    } else if (phone.startsWith('07') && phone.length === 10) {
      formattedPhone = `254${phone.substr(1)}`;
    } else if (phone.startsWith('7') && phone.length === 9) {
      formattedPhone = `254${phone}`;
    } else {
      formattedPhone = null;
    }
    return formattedPhone;
  };

  const handleAddPayment = () => {
    clearPhoneErrors();

    if (!paymentAmount || !phonenumber) {
      setEmptyfields('Please fill the amount field');
      return;
    }
    if (!phonenumber) {
      setEmptyfields('Please fill the phonenumber field');
      return;
    }

    const testedPhoneNumber = formatPhoneNumber(phonenumber);
    if (!testedPhoneNumber) {
      setPhoneFormatError('Invalid phone number format');
      return;
    }

    const paymentInt = parseInt(paymentAmount, 10);

    const transactionCharge = calculateTransactionCharge(paymentInt);

    totalTransactionCost += transactionCharge;

    const transactionAmount = paymentInt + transactionCharge;

    totalTransactionAmount += transactionAmount;

    const updatedPayments = [...payments, { amount: paymentInt, phone: testedPhoneNumber }];
    setPayments(updatedPayments);

    setTransactionAmount(totalTransactionAmount);
    setTransactionCost(totalTransactionCost);

    showToast('Payment Object added successfully');

    setPaymentAmount('');
    setPhoneNumber('');
  };

  const handleFormSubmit = async () => {
    setLoading(true);

    try {
      if (taskname === '' || periodValue === '' || date === '' || time === '') {
        setEmptyfields(true);
        return;
      }

      const initialDateTime = `${date}T${time}`;
      setInitialTime(initialDateTime);

      // console.log('Initial Time:', initialDateTime);

      const initialDate = new Date(initialDateTime);
      const now = new Date();

      const timeDifferenceMs = initialDate - now; // Difference in milliseconds
      // Convert milliseconds to different time units
      const timeDifferenceSeconds = Math.floor(timeDifferenceMs / 1000); // Convert to seconds
      const timeDifferenceDays = Math.floor(timeDifferenceMs / (1000 * 60 * 60 * 24)); // Convert to days
      const timeDifferenceHours = Math.floor(timeDifferenceMs / (1000 * 60 * 60)); // Convert to hours

      // console.log('Time Difference in milliseconds:', timeDifferenceMs);
      // console.log('Time Difference in seconds:', timeDifferenceSeconds);
      // console.log('Time Difference in days:', timeDifferenceDays);
      // console.log('Time Difference in hours:', timeDifferenceHours);

      let formattedPeriod;
      if (periodUnit === 'months') {
        formattedPeriod = `${periodValue * 30}d`;
      } else {
        formattedPeriod = `${periodValue}${periodUnit}`;
      }

      const requestBody = {
        payments,
        taskname,
        period: formattedPeriod,
        time : `${(timeDifferenceSeconds).toString()}s`,
      };

      // console.log(requestBody);

      const response = await bulkb2cpayments(user,requestBody);

      setResponse(response);
      setSuccess(true);
    } catch (error) {
      console.error(`Payment failed: ${error}`);
      setPaymentError(error);
      setError(true);
    }finally {
      setLoading(false);
    }

  };

  const calculateTransactionCharge = (deductedAmount) => {
    // Your existing table data
    const transactionChargeTable = [
        { min: 1, max: 49, registered: 0, unregistered: 8 },
        { min: 50, max: 100, registered: 0, unregistered: 8 },
        { min: 101, max: 500, registered: 5, unregistered: 8 },
        { min: 501, max: 1000, registered: 5, unregistered: 14 },
        { min: 1001, max: 1500, registered: 5, unregistered: 14 },
        { min: 1501, max: 2500, registered: 9, unregistered: 18 },
        { min: 2501, max: 3500, registered: 9, unregistered: 25 },
        { min: 3501, max: 5000, registered: 9, unregistered: 30 },
        { min: 5001, max: 7500, registered: 11, unregistered: 37 },
        { min: 7501, max: 10000, registered: 11, unregistered: 46 },
        { min: 10001, max: 15000, registered: 11, unregistered: 62 },
        { min: 15001, max: 20000, registered: 11, unregistered: 67 },
        { min: 20001, max: 25000, registered: 13, unregistered: 73 },
        { min: 25001, max: 30000, registered: 13, unregistered: 73 },
        { min: 30001, max: 35000, registered: 13, unregistered: 73 },
        { min: 35001, max: 40000, registered: 13, unregistered: null },
        { min: 40001, max: 45000, registered: 13, unregistered: null },
        { min: 45001, max: 50000, registered: 13, unregistered: null },
        { min: 50001, max: 70000, registered: 13, unregistered: null },
        { min: 70001, max: 250000, registered: 13, unregistered: null },
    ];
    
  
    const foundRow = transactionChargeTable.find((row) => deductedAmount >= row.min && deductedAmount <= row.max);
    return foundRow ? foundRow.registered : null;
  };
  
  const handlePaymentErrorButtonClick = () => {
    setError(false);
  };

  return (
    <>
     {loading && <ProcessingComponent />}
      {paymentError && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={handlePaymentErrorButtonClick} />}
      {success && <SuccessComponent response={response} handleSuccessButtonClick={handleSuccessButtonClick}/>}   
    {!success && !paymentError && !loading && (
       <Container>
       <Typography variant="h6" gutterBottom style={{ marginTop: '1rem', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
         Manual Entry Form
       </Typography>
       {step === 1 && (
         <Card>
           <CardContent>
             <IconButton onClick={handleBack} sx={{ marginBottom: 2 }}>
               <ArrowBack />
             </IconButton>
             <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
               Enter Standing Order Information
             </Typography>
             <Grid container spacing={2} style={{ marginBottom: 20 }}>
               <Grid item xs={12}>
                 <Typography variant="h6">Task Name</Typography>
                 <TextField
                   label="Task Name"
                   variant="outlined"
                   fullWidth
                   value={taskname}
                   onChange={(e) => setTaskName(e.target.value)}
                 />
               </Grid>
               <Grid item xs={12}>
                 <Typography variant="h6">Interval Period i.e 1 month</Typography>
                 <Grid container spacing={1} alignItems="center">
                   <Grid item xs={8}>
                     <TextField
                       label="Value"
                       variant="outlined"
                       fullWidth
                       type="number"
                       value={periodValue}
                       onChange={(e) => setPeriodValue(e.target.value)}
                     />
                   </Grid>
                   <Grid item xs={4}>
                     <Select
                       fullWidth
                       value={periodUnit}
                       onChange={(e) => setPeriodUnit(e.target.value)}
                     >
                       <MenuItem value='m'>Minutes</MenuItem>
                       <MenuItem value='h'>Hours</MenuItem>
                       <MenuItem value="d">Days</MenuItem>
                       <MenuItem value="w">Weeks</MenuItem>
                       <MenuItem value="months">Month(s)</MenuItem>
                       <MenuItem value='y'>Year</MenuItem>
                     </Select>
                   </Grid>
                 </Grid>
               </Grid>
               <Grid item xs={12}>
                 <Typography variant="h6">Initial Payment Time</Typography>
                 <Grid container spacing={1} alignItems="center">
                   <Grid item xs={6}>
                     <TextField
                       label="Date"
                       type="date"
                       value={date}
                       onChange={(e) => setDate(e.target.value)}
                       fullWidth
                       InputLabelProps={{ shrink: true }}
                     />
                   </Grid>
                   <Grid item xs={6}>
                     <TextField
                       label="Time"
                       type="time"
                       value={time}
                       onChange={(e) => setTime(e.target.value)}
                       fullWidth
                       margin="normal"
                     />
                   </Grid>
                 </Grid>
               </Grid>
             </Grid>
             {emptyfields && (
               <Typography variant="body2" color="error" style={{ marginTop: '1rem', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                 {emptyfields}
               </Typography>
             )}
             <Button
               onClick={handleNextStep}
               variant="contained"
               style={{ marginTop: '1rem', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
             >
               Proceed to setup recipients and amounts
             </Button>
           </CardContent>
         </Card>
       )}
       {step === 2 && (
         <>
            <Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
              Enter Payment and recipient Details
            </Typography>
           <TextField
             label="Amount"
             value={paymentAmount}
             type='number'
             onChange={handleAmountChange}
             fullWidth
             margin="normal"
           />
           <TextField
             label="Phone Number"
             value={phonenumber}
             onChange={(e) => setPhoneNumber(e.target.value)}
             fullWidth
             margin="normal"
             error={Boolean(phoneFormatError)}
             helperText={phoneFormatError}
           />
           {emptyfields && (
             <Typography variant="body2" color="error" style={{ marginTop: '1rem', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
               {emptyfields}
             </Typography>
           )}
           <Button
             onClick={handleAddPayment}
             variant="contained"
             style={{ marginTop: '1rem', fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
           >
             Add Payment
           </Button>
           <TableContainer>
             <Table>
               <TableHead>
                 <TableRow>
                   <TableCell>Amount</TableCell>
                   <TableCell>Phone Number</TableCell>
                   <TableCell>Actions</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody>
                 {payments.map((payment, index) => (
                   <TableRow key={index}>
                     <TableCell>{payment.amount}</TableCell>
                     <TableCell>{payment.phone}</TableCell>
                     <TableCell>
                       <IconButton onClick={() => handleDelete(index)}>
                         <Delete />
                       </IconButton>
                     </TableCell>
                   </TableRow>
                 ))}
               </TableBody>
             </Table>
           </TableContainer>
           <Grid container spacing={2} style={{ marginTop: '1rem' }}>
             <Grid item>
               <Button
                 onClick={handlePreviousStep}
                 variant="contained"
                 style={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                 startIcon={<ArrowBack />}
               >
                 Back
               </Button>
             </Grid>
             <Grid item>
               <Button
                 onClick={handleFormSubmit}
                 variant="contained"
                 disabled={loading}
                 style={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                 // endIcon={<ArrowForward />}
               >
                 Submit
               </Button>
             </Grid>
           </Grid>
         </>
       )}
     </Container>
    )}
  </>
  );
}