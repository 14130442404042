// UserContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const UserContext = createContext();

const url = process.env.REACT_APP_WEBSOCKET;

// console.log(url);

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    // Initialize state from sessionStorage if available
    const storedUser = sessionStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const setNewBalance = (newBalance) => {
    setUser((prevUser) => ({
      ...prevUser,
      balance: newBalance,
    }));
  
    // Update sessionStorage with the new balance
    sessionStorage.setItem(
      'user',
      JSON.stringify({
        ...user,
        balance: newBalance,
      })
    );
  };  

  
  const setNewSmsEnable = (smsbalance) => {
    setUser((prevUser) => ({
      ...prevUser,
      smsenabled: smsbalance,
    }));
  
    // Update sessionStorage with the new balance
    sessionStorage.setItem(
      'user',
      JSON.stringify({
        ...user,
        smsenabled: smsbalance,
      })
    );
  };  
  
  const setUserDetails = (userData) => {
    // Destructure the properties you want to keep
    // eslint-disable-next-line camelcase
    const { secret_key, username, email, balance, status, company_name,location,verificationstatus,shortcode,mmfbalance,
      firstname,lastname,permissions,hasuser,activestatus,aicredits,smsenabled,smsbalance, subscriptionend,ifadmin, hasotherbusiness 
    } = userData;

    const { phonenumber,bankcode,accountnumber,bank,url } = userData;
  
    // const phonenumber = phonenumber;
    const accountNumber = accountnumber;
    const Email = email;
    // Create a new object with only the properties you want to keep
    const updatedUserData = {
      // eslint-disable-next-line camelcase
      secretKey: secret_key,
      username,
      email,
      Email,
      phonenumber,
      location,
      balance,
      status,

      smsenabled,
      ifadmin,
      // Conditionally include phonenumber if it's present
      // ...(phonenumber && { phonenumber }),
      ...(bankcode && { bankcode }),
      ...(accountNumber && { accountNumber }),
      ...(bank && { bank }),
      ...(url && { url }),
      ...(verificationstatus && {verificationstatus}),
      ...(shortcode && {shortcode}),
      ...(mmfbalance && {mmfbalance}),
      ...(firstname && {firstname}),
      ...(lastname && {lastname}),
      ...(permissions && {permissions}),
      ...(hasuser && {hasuser}),
      ...(hasotherbusiness && {hasotherbusiness}),
      ...(activestatus && {activestatus}),  
      ...(aicredits && {aicredits}),
      ...(smsenabled && {smsenabled}),
      ...(subscriptionend && {subscriptionend}),
      ...(smsbalance && {smsbalance}),
      // eslint-disable-next-line camelcase
      companyName: company_name
    };
    
    // console.log(updatedUserData);

    setUser(updatedUserData);
  
    // Save the trimmed user details to sessionStorage
    sessionStorage.setItem('user', JSON.stringify(updatedUserData));
  };

  const reupdateUserDetails = () => {
    // Retrieve the current user details from sessionStorage
    const storedUser = sessionStorage.getItem('user');
    
    if (storedUser) {
      // Parse the user details
      const parsedUser = JSON.parse(storedUser);
      
      // Optional: Log the parsed data for debugging
      console.log(parsedUser);
  
      // Update the user details in state
      setUser(parsedUser);
      
      // Optionally, update sessionStorage again with the same data to ensure it's in sync
      sessionStorage.setItem('user', JSON.stringify(parsedUser));
    } else {
      console.error('No user data found in sessionStorage');
    }
  };
  
  
  useEffect(() => {
    // Cleanup sessionStorage on component unmount
    return () => {
      sessionStorage.removeItem('user');
    };
  }, []);

  // useEffect(() => {
  //   // const ws = new WebSocket(`wss://api.rotsi.co.ke`); // Update to your WebSocket URL
  //   const ws = new WebSocket(`wss://${url}`);
  //   ws.onmessage = (event) => {
  //     // eslint-disable-next-line camelcase
  //     const { secret_key, username } = JSON.parse(event.data);
  //     setUser((prevUser) => ({
  //       ...prevUser,
  //       // eslint-disable-next-line camelcase
  //       secretKey: secret_key,
  //       username,
  //     }));
  //   };

  //   return () => {
  //     ws.close();
  //   };
  // }, []);

  return (
    <UserContext.Provider value={{ user, setUserDetails, setNewBalance,setNewSmsEnable }}>
      {children}
    </UserContext.Provider>
  );
};