import React, {useEffect} from 'react';
import axios from 'axios';
import { Box, Button, Typography, Grid } from '@mui/material';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';

const WelcomeBufferForm = () => {
  const navigate = useNavigate();
  
  // Handle Google Login Success
  const handleGoogleLogin = (response) => {
    try {
      console.log(response);
      // Simulating backend login flow
      const userToken = response.access_token;

      // Save token or perform actions (e.g., API call)
      console.log('Google Token:', userToken);
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userToken}`, {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: 'application/json',
          },
        })
        .then((response) => {
          const data = response.data;
          const email = data.email;
          // given_name, family_name,email,picture
          // const name = data.name;
          // const given_name = data.given_name;
          // const family_name = data.family_name;
          // const picture = data.picture;
          console.log(data);          
          toast.success(`${email} Google Auth Success!`);
          navigate('/calendar-event');
        })
        .catch((error) => console.error(error));
    } catch (error) {
      console.error(error);
      toast.error('Google Auth Failed!');
    }
  };

  const gLogin = useGoogleLogin({
    // onSuccess: handleGoogleLogin,
    onSuccess: handleGoogleLogin,
    onError: (error) => {
      console.error('Google Login Failed:', error);
      toast.error('Login Failed!');
    },
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        textAlign: 'center',
        backgroundColor: '#f5f6fa',
      }}
    >
      <Grid item xs={10} md={6}>
        <Box>
          {/* Main Text */}
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              mb: 2,
              color: '#393e46',
            }}
          >
            An agent will reach out shortly.
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#7a7a7a',
              mb: 4,
            }}
          >
            Click the link below to select a time of your convenience.
          </Typography>

          {/* Button to Setup Calendar */}
          <Button
            variant="contained"
            fullWidth
            onClick={() => gLogin()}
            startIcon={<FcGoogle />}
            size="large"
            sx={{
              mb: 3,
              textTransform: 'none',
              color: '#fff',
              backgroundColor: '#4285F4',
              fontWeight: 'bold',
              borderRadius: '10px',
              '&:hover': {
                backgroundColor: '#357ae8',
              },
            }}
          >
            Setup Calendar
          </Button>

          {/* Navigate to Next Step */}
          <Button
            variant="text"
            onClick={() => navigate('/onboarding')}
            sx={{
              color: '#4285F4',
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '16px',
            }}
          >
            New mind? Navigate to the next step
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WelcomeBufferForm;

