import React, { useState, useEffect } from "react";
// import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import axios from "axios";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import { TextField, Button, Box, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { getCalendarBookings } from "../apis/generatelink";

// const localizer = momentLocalizer(moment);

const CalendarEvents = ({ userToken }) => {
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [startTime, setStartTime] = useState("09:00"); // Default start time
  const [endTime, setEndTime] = useState("11:00"); // Default end time

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getCalendarBookings();
        const bookedEvents = response.bookings.map((event) => ({
          title: "Booked",
          start: new Date(event.starttime),
          end: new Date(event.endtime),
          allDay: false,
        }));
        setEvents(bookedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
        toast.error("Failed to fetch events.");
      }
    }
    fetchData();
  }, [userToken]);

  const handleCreateEvent = () => {
    const startDate = new Date(range[0].startDate);
    const endDate = new Date(range[0].endDate);

    // Combine date with selected start and end times
    const start = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      ...startTime.split(":").map(Number) // Extract hours and minutes
    );

    const end = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      ...endTime.split(":").map(Number)
    );

    const duration = (end - start) / (1000 * 60 * 60); // Duration in hours

    if (duration > 2) {
      toast.error("You cannot book more than 2 hours at a time.");
      return;
    }

    axios
      .post(
        "/api/book-slot",
        { startTime: start, endTime: end },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then(() => {
        toast.success("Event booked successfully!");
        setEvents((prev) => [
          ...prev,
          {
            title: "Booked",
            start,
            end,
            allDay: false,
          },
        ]);
        setModalOpen(false);
      })
      .catch((error) => {
        console.error("Booking failed:", error);
        toast.error("Failed to book event. Try again.");
      });
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.title === "Booked" ? "#f44336" : "#4caf50";
    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "8px",
        border: "none",
        padding: "5px",
      },
    };
  };

  return (
    <div style={{ height: "80vh", padding: "20px" }}>
      <Typography variant="h4" align="center" gutterBottom>
        Calendar Booking
      </Typography>
      {/* <Calendar
        localizer={localizer}
        events={events}
        selectable
        onSelectSlot={() => setModalOpen(true)}
        startAccessor="start"
        endAccessor="end"
        defaultView="agenda"
        eventPropGetter={eventStyleGetter}
        style={{ height: "100%", backgroundColor: "#4abad8", borderRadius: "10px", padding: "10px" }}
      /> */}

      {modalOpen && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Create a New Event
          </Typography>
          <Box sx={{ mt: 2 }}>
            <TextField
              label="Start Time"
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 300 }} // 5 minutes step
              fullWidth
              margin="normal"
            />
            <TextField
              label="End Time"
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ step: 300 }}
              fullWidth
              margin="normal"
            />
          </Box>
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Button variant="contained" color="primary" onClick={handleCreateEvent} sx={{ mr: 2 }}>
              Confirm
            </Button>
            <Button variant="outlined" onClick={() => setModalOpen(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default CalendarEvents;