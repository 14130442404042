import React, { useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton, InputLabel, FormControl,MenuItem,Select,InputAdornment, Avatar,CircularProgress } from '@mui/material';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
import {ArrowBack,FileCopy,CloudUpload } from '@mui/icons-material';
import { ThreeDots } from 'react-loader-spinner';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { toast } from 'react-toastify';
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";

import Page from '../../components/Page';
import { useUserContext } from '../../UserContext';
import useAccount from '../../_mock/account'; // Assuming this mock provides account data
import { extraPersonnel } from '../../apis/auth';
import storage from '../../firebase';
import BusinessUsers from './SettingsBusinessUsers';
import MiniBusinesses from './SettingsMiniBusinesses';

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function Profile() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState('');
  const account = useAccount(); // Fetch the account data
  const [imageUrl, setImageUrl] = useState(account.photoURL); // State variable for original image URL
  const [uploadUrl, setUploadUrl] = useState(''); // State variable for newly uploaded image URL
  // const [response, setResponse] = useState(null); // State to hold response data
  const [isUploading, setUploading] = useState(false); 
  const [success, setSuccess] = useState(false);
  const [selectedRole, setSelectedRole] = useState(false); // State for selected role (Developer or Employee)
  const [developerFields, setDeveloperFields] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmpassword: '',
    permissions: 0,
  });
  const [employeeFields, setEmployeeFields] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmpassword: '',
    permissions: 0,
  });
  const [response, setResponse] = useState(null); // State to hold response data

  const { user,setUserDetails } = useUserContext();
  const navigate = useNavigate();

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleFieldChange = (field, value) => {
    setError('');
    if (selectedRole === 'developer') {
      setDeveloperFields({ ...developerFields, [field]: value });
    } else if (selectedRole === 'employee') {
      setEmployeeFields({ ...employeeFields, [field]: value });
    }
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleBack = () => {
    setSelectedRole(false);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    
    // Ensure the file is an image file
    if (file.type.startsWith('image/')) {
      setUploading(true);
  
      try {
        // console.log("Handling image upload...");
        
        const imageRef = storageRef(storage, `profile_images/${user.username}`);
        
        // Upload the image file directly without any conversion
        uploadBytes(imageRef, file)
          .then((snapshot) => {
            getDownloadURL(snapshot.ref)
              .then((url) => {
                setUploadUrl(url);
                toast.success("Image uploaded successfully!");
                // console.log("Image uploaded successfully:", url);
              })
              .catch((error) => {
                // console.error('Failed to get download URL:', error);
                toast.error('Failed to get download URL. Please try again later.');
              });
          })
          .catch((error) => {
            // console.error('Failed to upload image:', error);
            toast.error('Failed to upload image. Please try again later.');
          });
      } catch (error) {
        // console.error('Failed to handle image upload:', error);
        toast.error('Failed to handle image upload. Please try again later.');
      } finally {
        setUploading(false);
      }
    } else {
      // Handle the case where the selected file is not an image
      toast.error('Please select an image file.');
    }
  };
  
  const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Adding user...
        </Typography>
      </div>
    </Container>
  );  

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      let requestBody;

      if (selectedRole === 'developer') {
        // Logic to add developer
        if(developerFields.password !== developerFields.confirmpassword){
          setError("Sorry, but the Passwords must match");
          setIsSubmitting(false);
          return;
        }
        // console.log('Adding Developer:', developerFields);
        requestBody = {
          firstname:developerFields.firstname,
          lastname: developerFields.lastname,
          Email:developerFields.email,
          selectedRole:'staff',
          password:developerFields.password,
          permissions:developerFields.permissions,          
        };

      } else if (selectedRole === 'employee') {
        // Logic to add employee
        // console.log(employeeFields);
         // Logic to add employee
        if (!employeeFields.firstname || !employeeFields.lastname || !employeeFields.password || !employeeFields.confirmpassword || !employeeFields.email) {
          setError('All fields must be filled');
          setIsSubmitting(false);
          return;
        }
        if(employeeFields.password !== employeeFields.confirmpassword){
          setError("Sorry, but the Passwords must match");
          setIsSubmitting(false);
          return;
        }
        // console.log('Adding Employee:', employeeFields);
        requestBody = {
          firstname:employeeFields.firstname,
          lastname: employeeFields.lastname,
          Email:employeeFields.email,
          selectedRole:'staff',
          password:employeeFields.password,
          permissions:employeeFields.permissions,          
        };
      }
      // console.log(requestBody);
      const response = await extraPersonnel(user,requestBody);
      setSelectedRole(false);
      setResponse(response.message);
      setSuccess('New Staff User has been Added. He/She has also been alerted via Email, with necessary credentials')
      toast.success('New Staff User has been Added.');
    } catch (error) {
      if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
        navigate('/login');
      } 
    } finally {
      setIsSubmitting(false);
      setIsEditing(false);
    }
  }; 

  return (
    <Page title="Rotsi Settings page">
      <Container>
          {isSubmitting && <ProcessingComponent />}
          {!isSubmitting && 
          <div>
            {selectedRole === false && (
              <>
                 {!uploadUrl && 
                    <Avatar src={imageUrl} alt="photoURL" sx={{ width: 100, height: 100, margin: '0 auto' }} /> 
                  }

                  {success && 
                      <Typography variant="body1" style={{ color:"green", marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                        {success}
                      </Typography>
                    }
                
                  <Typography variant="h5" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom align="center">
                    {capitalizeFirstLetter(account.displayName)}
                  </Typography>        

                  <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      value={isEditing ? user.email : user.email}
                      onChange={(e) => {}}
                      sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                      disabled={!isEditing}
                    />
                  </FormControl>

                  <FormControl fullWidth sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
                    <TextField
                      label="Secret Key"
                      variant="outlined"
                      value={user.secretKey}
                      onChange={(e) => {}}
                      sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
                      disabled={!isEditing}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => {
                              navigator.clipboard.writeText(user.secretKey);
                              toast.info('Secret Key copied to clipboard');
                            }}>
                              <FileCopy/>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  {
                    user.ifadmin && <MiniBusinesses />
                  }

                  {
                    parseInt(user.hasuser, 10) === 1 ? (
                        <BusinessUsers />
                    ) : (
                      <>
                          <Typography
                              variant="body2"
                              align="left"
                              display="flex"
                              alignItems="left"
                              justifyContent="left"
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                              marginTop="10px"
                          >
                              Did you know you can add another employee-user to your account? Click to know more...
                          </Typography>
                                
                          <Typography
                              variant="body2"
                              align="left"
                              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                              marginTop="10px"
                          >
                              Select role to add:
                          </Typography>

                          <FormControl fullWidth sx={{ marginBottom: 2 }}>
                              <Select
                                  value={selectedRole}
                                  onChange={handleRoleChange}
                                  displayEmpty
                                  fullWidth
                                  variant="outlined"
                                  sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                              >
                                  <MenuItem value="" disabled>
                                      Select Role
                                  </MenuItem>
                                  <MenuItem value="employee">Employee</MenuItem>
                              </Select>
                          </FormControl>
                      </>
                    )
                }
              </>
            )}

            {selectedRole === 'developer' && (
              <Container>
                <IconButton onClick={handleBack} sx={{ marginBottom: 2 }}>
                  <ArrowBack />
                </IconButton>
              
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    value={developerFields.firstname}
                    onChange={(e) => handleFieldChange('firstname', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    value={developerFields.lastname}
                    onChange={(e) => handleFieldChange('lastname', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={developerFields.email}
                    onChange={(e) => handleFieldChange('email', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={developerFields.password}
                    onChange={(e) => handleFieldChange('password', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    value={developerFields.confirmpassword}
                    onChange={(e) => handleFieldChange('confirmpassword', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Choose permissions for this user.</InputLabel>
                    <Select
                      value={developerFields.permissions}
                      onChange={(e) => handleFieldChange('permissions', e.target.value)}
                      label="Choose permissions for this user."
                    >
                      {/* <MenuItem >Choose permissions for this user.</MenuItem> */}
                      <MenuItem value={0}>Receive money in favor of the business</MenuItem>
                      <MenuItem value={1}>Receive and make only phone payouts</MenuItem>
                      <MenuItem value={2}>Receive and make both phone and bank payouts</MenuItem>
                    </Select>
                  </FormControl>
                  {error && (
                    <Container>
                      <Typography variant="body2" color="error" gutterBottom>
                        {error}
                      </Typography>
                    </Container>
                  )}
                </FormControl>
                {!isUploading && (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !developerFields.lastname || !developerFields.firstname || !developerFields.password || !developerFields.confirmpassword || !developerFields.email}
                    onClick={handleFormSubmit}
                    sx={{ display: 'block', margin: '0 auto', width: '200px',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                  >
                    <span>{isSubmitting ? 'Adding User' : 'Add User'}</span>
                  </Button>
                )}
              </Container>
            )}

            {selectedRole === 'employee' && (
              <Container>
                <IconButton onClick={handleBack} sx={{ marginBottom: 2 }}>
                  <ArrowBack />
                </IconButton>
                <FormControl fullWidth sx={{ marginBottom: 2 }}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    value={employeeFields.firstname}
                    onChange={(e) => handleFieldChange('firstname', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    value={employeeFields.lastname}
                    onChange={(e) => handleFieldChange('lastname', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    type="email"
                    value={employeeFields.email}
                    onChange={(e) => handleFieldChange('email', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={employeeFields.password}
                    onChange={(e) => handleFieldChange('password', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Confirm Password"
                    variant="outlined"
                    type="password"
                    value={employeeFields.confirmpassword}
                    onChange={(e) => handleFieldChange('confirmpassword', e.target.value)}
                    sx={{ marginBottom: 2 }}
                  />
                  <FormControl fullWidth sx={{ marginBottom: 2 }}>
                    <InputLabel>Choose permissions for this user.</InputLabel>
                    <Select
                      value={employeeFields.permissions}
                      onChange={(e) => handleFieldChange('permissions', e.target.value)}
                      label="Choose permissions for this user."
                    >
                      {/* <MenuItem >Choose permissions for this user.</MenuItem> */}
                      <MenuItem value={0}>Receive money in favor of the business</MenuItem>
                      <MenuItem value={1}>Receive and make only phone payouts</MenuItem>
                      <MenuItem value={2}>Receive and make both phone and bank payouts</MenuItem>
                    </Select>
                  </FormControl>
                  {error && (
                    <Container> 
                      <Typography variant="body2" color="error" gutterBottom>
                        {error}
                      </Typography>
                    </Container>
                  )}
                </FormControl>
                {!isUploading && (
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !employeeFields.lastname || !employeeFields.firstname || !employeeFields.password || !employeeFields.confirmpassword || !employeeFields.email}
                    onClick={handleFormSubmit}
                    sx={{ display: 'block', margin: '0 auto', width: '200px',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}
                  >
                    <span>{isSubmitting ? 'Adding User' : 'Add User'}</span>
                  </Button>
                )}  
              </Container> 
            )}
          </div>
        }
      </Container>
    </Page>
  );
}