import { useState } from 'react';
import { useNavigate, Navigate,Link as RouterLink } from 'react-router-dom';
import { Container, Typography, TextField, Button, IconButton, FormControl, InputAdornment,Card,CardContent,Link } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify'; // Import toast library
import { ThreeDots } from 'react-loader-spinner';
import { Checkmark } from 'react-checkmark'
import { IoMdAlert } from 'react-icons/io';
import Page from '../../components/Page';
import { useUserContext } from '../../UserContext';
import { dynamicQrcode } from '../../apis/paymentapis'; // Import dynamicQrcode function
import QrCodeComponent from '../QrCode';

export default function DynamicQrCode() {
  const { user, setNewBalance } = useUserContext();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [amount, setAmount] = useState('');
  const [paymentError, setPaymentError] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [amountError, setAmountError] = useState('');
  const [response, setResponse] = useState(null); // State to hold response data

  let balance;

  if (!user) {
    return <Navigate to="/login" />;
  }

  if(user){
    balance = user.balance;
  }

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleAmountChange = (e) => {
    const { value } = e.target;
    setAmount(value);
    // Clear phoneError when the user starts typing again
    setError('');
  };

  const handleReturnButtonClick = () => {
    setSuccess(false); // Set success state back to false
    navigate('/business-dashboard/app');
  };

  const ErrorComponent = ({ paymentError, handlePaymentErrorButtonClick }) => (
    <div style={{ textAlign: 'center' }}>
      <IoMdAlert size={24} color="red" />
      <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>An error occurred!</Typography>
      <Typography variant="body1" color="error" style={{ marginTop: 10 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>{paymentError}</Typography>
      <Button onClick={handlePaymentErrorButtonClick} variant="contained" style={{ marginTop: 20 ,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
        Try Again
      </Button>
    </div>
  );  

  const ProcessingComponent = () => (
    // View when processing
    <Container style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh', // Ensures the container takes the full height of the viewport
    }}>
      <div style={{ textAlign: 'center' }}>
        <ThreeDots visible height="80"width="80" color="#4fa94d" radius="9"ariaLabel="three-dots-loading" wrapperStyle={{}} wrapperClass=""/>
        <Typography variant="body1" style={{ marginTop: 10, fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Processing Payment...
        </Typography>
      </div>
    </Container>
  );  
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
        const amountInt = parseInt(amount, 10);
        // Check if amount is above 0
        if (amountInt <= 0 || Number.isNaN(amountInt)) {
            setAmountError('Amount must be a positive number.');
            setIsSubmitting(false);
            return;
        }
        const amountWithoutDecimals = Math.trunc(amountInt);

        // Prepare the request body
        const requestBody = {
            amount: amountWithoutDecimals
        };

        // console.log('Request Body:', requestBody);

        // Perform the withdrawal operation
        const responseData = await dynamicQrcode(user, requestBody);
        console.log(responseData);
        // setResponse(responseData.QRCode);
        // setSuccess(true);
        // Display success message and navigate to login page
        // toast.success('Rotsi Pay-in successful!');
        // navigate('/login');
    } catch (error) {
      if(error.message){
        if(error.message === 'You are not authorized to access this API. Visit rotsi.co.ke to acquire one'){
          navigate('/login');
        }else{
          setError(true);
          setPaymentError(error.message);
        }
      } else{
        setError(true);
        setPaymentError('Withdrawal failed. Please try again later.');
      }
    } finally {
        setIsSubmitting(false);
        setIsEditing(false);
    }
  };

  return (
    <Page title="Rotsi QrCode Pay-ins and Deposits">
      <Container>
      {isSubmitting && <ProcessingComponent />}
      {paymentError && <ErrorComponent paymentError={paymentError} handlePaymentErrorButtonClick={() => setPaymentError('')} />}
      {success && <QrCodeComponent response={response} handleReturnButtonClick={handleReturnButtonClick} />}
      {!isSubmitting && !success && !paymentError && (
        <div>
        <Typography variant="h4" gutterBottom sx={{ marginBottom: 8, color: 'black',fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Receive payments straight to your Rotsi Wallet easily and efficiently.
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ marginBottom: 2,fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem' }}>
          Easily receive payment by scanning a QrCode
        </Typography>
        <form onSubmit={handleFormSubmit}>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <TextField
              label="Amount you wish to deposit"
              variant="outlined"
              value={amount}
              sx={{ fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}}
              type="number"
              onChange={handleAmountChange}              
            />
          </FormControl>
          {amountError && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {amountError}
            </Typography>
          )}
          {error && (
            <Typography variant="body2" color="error" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} gutterBottom>
              {error}
            </Typography>
          )}
          
          <LoadingButton type="submit" variant="contained" sx={{fontWeight: 'bold', fontFamily: 'Times New Roman', fontSize: '1rem'}} disabled={isSubmitting}>
            {isSubmitting ? `Generating QrCode ...`: 'Generate Payment Code'}
          </LoadingButton>
        </form>
      </div>
      )}
      </Container>
    </Page>
  );
}